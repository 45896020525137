<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined >
                    <v-container fluid color="blue lighten-5">
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Region </h6> 
                                <v-autocomplete 
                                    dense 
                                    solo 
                                    class="ma-0 border-12" 
                                    hide-details=true 
                                    v-model="region"
                                    @change="(event) => regionOnChange(event)" 
                                    :items="regions" 
                                    default="" 
                                    item-value="region" 
                                    item-text="region" 
                                    label="Region"
                                    :disabled="disabledRegion" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Branch </h6>
                                <v-autocomplete 
                                    dense
                                    solo class="ma-0 border-12" 
                                    hide-details=true 
                                    v-model="office"
                                    @change="(event) => officeOnChange(event)" 
                                    :items="offices" 
                                    default="" 
                                    item-value="office_id" 
                                    item-text="office" 
                                    label="Branch" 
                                    :disabled="disabledOffice" 
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Sales </h6>
                                <v-autocomplete 
                                    dense 
                                    solo 
                                    class="ma-0 border-12" 
                                    hide-details=true 
                                    v-model="sales"
                                    :items="saleses" 
                                    default="" 
                                    item-value="SalesId" 
                                    item-text="NamaSales"
                                    label="Sales" 
                                    :disabled="disabledSales" 
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">From </h6>
                                <v-menu 
                                    ref="modal_from" 
                                    v-model="modal_from" :close-on-content-click="false"
                                    transition="scale-transition" 
                                    offset-y max-width="290" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }"> 
                                        <v-text-field solo 
                                            dense
                                            clearable
                                            v-model="date_from" 
                                            label="Start Date" 
                                            persistent-hint
                                            append-icon="mdi-calendar" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="ma-0 pa-0 mb-1 border-12"
                                            hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker type="date" v-model="date_from" no-title @input="modal_from = false"></v-date-picker>
                                </v-menu>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">To </h6>
                                <v-menu 
                                    ref="modal_to" 
                                    v-model="modal_to" :close-on-content-click="false"
                                    transition="scale-transition" 
                                    offset-y max-width="320px" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo 
                                            dense
                                            clearable
                                            v-model="date_to" 
                                            label="End Date" 
                                            persistent-hint
                                            append-icon="mdi-calendar" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="ma-0 pa-0 mb-1 border-12"
                                            hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-8 border-12" 
                                style="padding: 19px;" 
                                small color="primary" 
                                elevation="2" 
                                @click="SearchChart()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="6" md="3">
                <v-card outlined class="p-4 elevation-1" color="blue lighten-5">  
                    <h6 class="blue lighten-5 m-0">Grand Summary</h6>
                    <div id="shop_summary" style="height: 385px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="9" >
                <v-card>  
                    <v-data-table 
                    fixed-header
                    dense
                    height="320"
                    :headers="headerss"
                    :items="dttable" 
                    class="elevation-1 mt-2" 
                    :items-per-page="10"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                    }"
                    :search="searchSummary" 
                    :loading="loading">
                        <template v-slot:top>
                            <v-toolbar flat color="blue lighten-5">
                                <div class="d-flex w-100">
                                    <h6 class="blue lighten-5 text-darken-3 m-0">Summary</h6>
                                    <v-spacer></v-spacer>
                                    <v-text-field solo style="max-width: 300px;" class="border-12" v-model="searchSummary" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                </div>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span>Show</span>
                                    </v-tooltip>
                                </template> 
                    </v-data-table>
                </v-card>
            </v-col>
            <v-dialog v-model="dialogdetail" max-width="1200px">                
                    <v-card>
                        <v-card-title>Detail</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table 
                                        :headers="headersdt" 
                                        :items="detaildt" 
                                        class="elevation-1" 
                                        page-count="5" 
                                        :search="searchDetail" 
                                        :loading="loading3">
                                            <template v-slot:top>
                                                <v-toolbar flat color="white">
                                                    <div class="d-flex w-25">
                                                        <v-text-field v-model="searchDetail" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-row>
    </v-container>
</template>

<script>
import {backendApi} from "../../../backend-api/backend-api-sr"
import {bad_customer} from "../../../backend-api/kmb/bad_customer"

export default{
    data() {
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'KMB',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'Branch Perfomance',
                    disabled: true,
                    href: '/admin/kmb',
                },
                {
                    text: 'Bad Customer',
                    disabled: true
                }
            ],
            detaildt:[],
            headersdt:[
            {
                text: 'No Order Customer',
                align: 'start',
                value: 'CustomerOrderNo', 
                align:'left'},
                { text: 'Nama Customer', value: 'NamaCustomer' , align:'left'},
                { text: 'Office ID', value: 'office_id' , align:'center'},
                // { text: 'Pelunasan', value: 'Pelunasan' , align:'center'},
                { text: 'Pay Term', value: 'pay_term' , align:'center'},
                { text: 'Dif', value: 'dif' , align:'center'},
                { text: 'Tanggal Jatuh Tempo', value: 'TglJTempo' , align:'center'},
            ],
            dttable:[],
            headerss:[
                {
                text: 'Customer Id',
                align: 'start',
                value: 'CustomerId', 
                align:'left'},
                { text: 'Nama Customer', value: 'NamaCustomer' , align:'left'},
                // { text: 'Lunas', value: 'lunas' , align:'center'},
                // { text: 'Belum', value: 'belum' , align:'center'},
                { text: 'Good', value: 'good' , align:'center'},
                { text: 'Bad', value: 'bad' , align:'center'},
                { text: 'Detail', value: 'actions' , align:'center'},
                
                
            ],
            regions:[],
            modal_from:false,
            modal_to:false,
            date_from:'',
            searchSummary:'',
            date_to:'',
            region:'',
            dialogdetail:false,
            loading3: false,
            searchDetail: '',
            offices:[],
            shop_summary: {},
            office:'',
            saleses:[],
            sales:'',
            disabledRegion:false,
            disabledOffice:false,
            disabledSales:false,
        }
    },
    
    async mounted() {
        await this.getFilter()
    },
    methods: {
        async getFilter(){
            var respData = await bad_customer.filter(`?user_group=${this.$store.state.kmb.group_id}`, null, false, false, false)
            this.regions = respData.data.region
            if(this.regions.length == 1){
                this.disabledRegion = true
                this.region = this.regions[0]
            }
            this.offices = respData.data.office
            if(this.offices.length == 1){
                this.disabledOffice = true
                this.office = this.offices[0]
            }
            this.saleses = respData.data.sales
            if(this.saleses.length == 1){
                this.disabledSales = true
                this.sales = this.saleses[0]
            }
        },
        async regionOnChange(value){
            // var respData = await bad_customer.filterRegion(`?user_group=${this.$store.state.kmb.group_id}`, null, false, false, false)
            var respData = await axios.get(`${process.env.VUE_APP_URL}/v2/kmb/bad_customer_order/filter-region-change?region=${this.region ? this.region : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            this.offices = respData.data.office
            this.saleses = respData.data.sales
        },
        async officeOnChange(value){
            var respData = await bad_customer.filterOffice(`?user_group=${this.$store.state.kmb.group_id}`, null, false, false, false)
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/sales?office_id=${this.office ? this.office : ''}&SalesId=${this.sales ? this.sales : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_sales = false
                this.saleses = res.data.data
            });
        },

        close(){
            this.dialogdetail = false
        },
        showItem(item){
            console.log(item);
            this.CustomerId = item
            this.NamaCustomer = item
            this.bad = item
            this.belum = item
            this.dialogdetail = true
            this.SearchDetail()
        },
        async SearchDT() {
            try {
                this.$store.dispatch('setOverlay', true)
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
                var respData = await bad_customer.topPerformer(`?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ""}&sales_id=${this.sales ? this.region : ""}&tgl_pesan_start=${this.date_from ? this.date_from : ""}&tgl_pesan_end=${this.date_to ? this.date_to : ""}`, null, false, false, false);
                if (respData.status === 200) {
                    this.$store.dispatch('setOverlay', false)
                // console.log(respData.data.data.grandGrandSummary);
                this.dttable = respData.data.data.grandSummary
                } else {
                    this.$store.dispatch('setOverlay', false)
                }
            } catch (error) {
                
            }
        },
        async SearchDetail() {
            try {
                this.$store.dispatch('setOverlay', true)
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
                var respData = await bad_customer.detail(`?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ""}&sales_id=${this.sales ? this.region : ""}&tgl_pesan_start=${this.date_from ? this.date_from : ""}&tgl_pesan_end=${this.date_to ? this.date_to : ""}&customer_id=${this.CustomerId ? this.CustomerId.CustomerId : ""}`, null, false, false, false);
                if (respData.status === 200) {
                    this.$store.dispatch('setOverlay', false)
                // console.log(respData.data.data.grandGrandSummary);
                this.detaildt = respData.data.data
                } else {
                    this.$store.dispatch('setOverlay', false)
                }
            } catch (error) {
                
            }
        },
        async SearchChart() {
            try {
                if (this.date_from == '' || this.date_from === null) {
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Opps.. !",
                        text: 'Please Insert Complate Start Date & End Date !',
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                    this.dialog = true
                    return false
                }
                this.$store.dispatch('setOverlay', true)
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
                var respData = await bad_customer.topPerformer(`?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ""}&sales_id=${this.sales ? this.region : ""}&tgl_pesan_start=${this.date_from ? this.date_from : ""}&tgl_pesan_end=${this.date_to ? this.date_to : ""}`, null, false, false, false);
                if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                // console.log(respData.data.data.grandGrandSummary);
                this.renderHutang(respData.data.data.grandGrandSummary);
                this.SearchDT()
                } else {
                    this.$store.dispatch('setOverlay', false)
                }
            } catch (error) {
                
            }
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
    renderHutang(data) {
        console.log(data[0]);
    if (data && data.length > 0) {
        var chart4 = new CanvasJS.Chart("shop_summary", {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light2",
            title: {
                fontFamily: "Calibri",
                fontSize: 20
            },
            data: [{
                type: "pie",
                click: this.onClickShop,
                startAngle: 240,
                indexLabel: "{label} ({y})",
                toolTipContent: "<b>{label}:</b> {y} ({y})",
                dataPoints: [
                    { color:'#26DA6D', label: "Good", y: parseFloat(data[0].good)},
                    { color:'#FF093D', label: "Bad", y: parseFloat(data[0].bad)}
                ]
            }]
        });
        chart4.render();
    } else {
        this.$store.dispatch('setOverlay', false);
    }
    
    }
    
},
    watch: {

    },

    }
</script>