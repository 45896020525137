import {backendApi} from "../backend-api-sr"

export const bad_customer = (() =>{
    const index = ( async (subPath) => {
        var url = '/v2/kmb/bad_customer_order'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const filter = ( async (subPath) => {
        var url = '/v2/kmb/bad_customer_order/filter'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const filterRegion = ( async (subPath) => {
        var url = '/v2/kmb/bad_customer_order/filter-region'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const filterOffice = ( async (subPath) => {
        var url = '/v2/kmb/bad_customer_order/filter-offices'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const topPerformer = ( async (subPath) => {
        var url = '/v2/kmb/bad_customer_order'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const detail = ( async (subPath) => {
        var url = '/v2/kmb/bad_customer_order/detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    return {index, filter, filterRegion, filterOffice, topPerformer, detail};
})()